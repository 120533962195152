const Utility = {
  up: 38,
  left: 37,
  right: 39,
  down: 40,
  enter: 13,
  back: 8,
  // back:10009,
  // channelUp:104,
  // channelDown:98,
  channelUp: 427,
  channelDown: 428,
  play: 415,
  puase: 19,
  redbutton: 403,
};

// export const registerKeys = function(keys) {
//     tizen.tvinputdevice.registerKeyBatch(keys);
// };

export default Utility;
